<template>
  <div class="main-contain_con">
    <div class="contain_up">
      <div class="title_con">
        <div class="title_text_border">
          <div class="left_circle"></div>
          <div class="right_circle"></div>
          <div class="btn_group">
            <el-button class="bt_plain" v-if="courseinfo" plain round @click="toSelection">学校选课</el-button>
            <el-button class="bt" round @click="tolearning" v-if="courseinfo.is_join == 0">
              <div>加入学习</div>
              <!-- <div v-if="courseinfo.is_join == 1">进入学习</div> -->
            </el-button>
          </div>
          <div class="title_text">{{ courseinfo ? courseinfo.name : "" }}</div>
          <div class="time_text">
            开课时间: {{ courseinfo.start_time | dataformat }} ~
            {{ courseinfo.end_time | dataformat }}
          </div>
        </div>
      </div>
    </div>
    <div class="contain_down">
      <div class="left_cont">
        <div class="title_con">
          <div class="title">
            <div class="title-left">
              <el-image class="elimage" :src="courseinfo.cover" />
              <el-image class="statusimg" :src="statusImg" />
            </div>
            <div class="title-right">
              <div class="intro-title">
                <div class="item">
                  <!-- <el-image class="elimage" :src="icon2" /> -->
                  <div class="item-name">所属学科：</div>
                  <div class="item-text">
                    {{
                        courseinfo && courseinfo.subject_config_info
                          ? courseinfo.subject_config_info.name
                          : ""
                    }}
                  </div>
                </div>
                <div class="item">
                  <!-- <el-image class="elimage" :src="icon13" /> -->
                  <div class="item-name">课程性质：</div>
                  <div class="item-text">
                    {{
                        courseinfo && courseinfo.course_type_config_info
                          ? courseinfo.course_type_config_info.name
                          : ""
                    }}
                  </div>
                </div>
                <div class="item">
                  <!-- <el-image class="elimage" :src="icon12" /> -->
                  <div class="item-name">课程类别：</div>
                  <div class="item-text">
                    {{
                        courseinfo && courseinfo.course_classify_config_info
                          ? courseinfo.course_classify_config_info.name
                          : ""
                    }}
                  </div>
                </div>
                <div class="item">
                  <!-- <el-image class="elimage" :src="icon1" /> -->
                  <div class="item-name">教师：</div>
                  <div class="item-text">
                    {{
                        courseinfo && courseinfo.leading_user_info
                          ? courseinfo.leading_user_info.name
                          : ""
                    }}
                  </div>
                </div>
              </div>

              <div class="title-footer">
                <div class="item">
                  <div class="item-bottom">
                    <div class="num">
                      {{ courseinfo ? courseinfo.credit : "" }}
                    </div>
                    <div class="num-title">学分</div>
                  </div>
                </div>

                <div class="item">
                  <div class="item-bottom">
                    <div class="num num2">
                      {{ courseinfo ? courseinfo.class_hours : "" }}
                    </div>
                    <div class="num-title">学时</div>
                  </div>
                </div>

                <div class="item">
                  <div class="item-bottom">
                    <div class="num num3">
                      {{ courseinfo ? courseinfo.join_count : "" }}
                    </div>
                    <div class="num-title">加入人数</div>
                  </div>
                </div>

                <div class="item">
                  <div class="item-bottom">
                    <div class="num num4">
                      {{ courseinfo ? courseinfo.collect_count : "" }}
                    </div>
                    <div class="num-title">累计收藏数</div>
                  </div>
                </div>
              </div>

              <div class="title-right-head">
                <div class="right-bt">
                  <div class="bt2" @click="collectbt">
                    <template v-if="courseinfo.is_collect === 0">
                      <el-image class="elimage" :src="collecticon1" />
                      <div class="text">收藏</div>
                    </template>
                    <template v-if="courseinfo.is_collect === 1">
                      <el-image class="elimage" :src="collecticon2" />
                      <div class="text">已收藏</div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom">
          <div class="bottom_con">
            <div class="tabbt-contain">
              <div :class="activebt == 'bt1' ? 'bt btactive' : 'bt'" @click.stop="btclick('bt1', 'Courseintroduction')">
                <div :class="
                  activebt == 'bt1' ? 'bt-name bt-name-active' : 'bt-name'
                ">
                  课程介绍
                </div>
              </div>

              <div :class="activebt == 'bt2' ? 'bt btactive' : 'bt'" @click="btclick('bt2', 'Teachteam')">
                <div :class="
                  activebt == 'bt2' ? 'bt-name bt-name-active' : 'bt-name'
                ">
                  教学团队
                </div>
              </div>

              <div :class="activebt == 'bt3' ? 'bt btactive' : 'bt'" @click="btclick('bt3', 'Courseoutline')">
                <div :class="
                  activebt == 'bt3' ? 'bt-name bt-name-active' : 'bt-name'
                ">
                  课程大纲
                </div>
              </div>

              <div :class="activebt == 'bt4' ? 'bt btactive' : 'bt'"
                @click="btclick('bt4', 'Virtualsimulationexperiment')">
                <div :class="
                  activebt == 'bt4' ? 'bt-name bt-name-active' : 'bt-name'
                ">
                  虚拟仿真实验
                </div>
              </div>
            </div>
            <component :is="componentId" ref="mycomponent"></component>
          </div>
        </div>
      </div>

      <div class="right_cont" v-if="resCourseList.length > 0">
        <div class="title">相关课程</div>
        <div class="rec_course">
          <div v-for="(item, inx) in resCourseList" :key="inx" class="course_block" @click="to_detail(item)">
            <div class="course_block_img">
              <el-image :src="item.cover" />
            </div>
            <el-tooltip :content="item.name" placement="top" effect="light" v-if="item.flag">
              <div class="course_block_text">{{ item.name }}</div>
            </el-tooltip>
            <div class="course_block_text" v-else>{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>

    <Customdialog ref="customdialog" :showclose="true" :title="dialogTitle" width="30%" type="min0">
      <div class="dialogbody" slot="dialogbody">
        <div class="tips">{{ tipsText }}</div>
        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-position="left" label-width="80px"
          class="demo-ruleForm">
          <el-form-item label="学校:" prop="school_name">
            <el-input v-model="ruleForm.school_name" placeholder="请输入学校" clearable></el-input>
          </el-form-item>
          <el-form-item label="姓名:" prop="user_name">
            <el-input v-model="ruleForm.user_name" placeholder="请输入姓名" clearable></el-input>
          </el-form-item>
          <el-form-item label="教工号:" prop="staff_number">
            <el-input v-model="ruleForm.staff_number" placeholder="请输入教工号" clearable></el-input>
          </el-form-item>
          <el-form-item label="密码:" prop="pass">
            <el-input v-model="ruleForm.pass" auto-complete="new-password" show-password clearable
              placeholder="请输入密码"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="dialogfooter" class="dialogfooter">
        <div class="score-bt">
          <el-button class="cancelbt" @click="dialogCancel('ruleForm')">取消</el-button>
          <el-button class="submitbt" @click="dialogSubmit('ruleForm')">确定</el-button>
        </div>
      </div>
    </Customdialog>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { getToken } from "@/utils/auth";
import { getcourseinfo, joincourse, getRecCourseList } from "@/api/course";
import { setuserfavorite } from "@/api/userfavorite";
import Courseintroduction from "./components/courseintroduction.vue";
import Teachteam from "./components/teachteam.vue";
import Courseoutline from "./components/courseoutlines.vue";
import Virtualsimulationexperiment from "./components/virtualsimulationexperiments.vue";
import Videoshow from "./components/videoshow.vue";
import Customdialog from "@/components/customdialog.vue";
export default {
  name: "mainpage",
  components: {
    Courseintroduction,
    Teachteam,
    Courseoutline,
    Virtualsimulationexperiment,
    Videoshow,
    Customdialog
  },
  inject: ['reload'],
  data() {
    let validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码!'));
      } else {
        if (value.length < 6) {
          callback(new Error('密码长度至少6位!'));
        } else {
          callback();
        }
      }
    };
    return {
      token: getToken() ? true : false,
      // btloading: false,
      // tolearnstate: 0, 
      collectstate: 0,
      courseinfo: "",
      componentId: "Courseintroduction",
      activebt: "bt1",
      total: 0,
      searchvalue: "",
      statusImg: "",
      is_join: "",//0 加入学习 1进入学习
      statusImg1: require("@/assets/coursedetails/st_not_start.png"),
      statusImg2: require("@/assets/coursedetails/st_ing.png"),
      statusImg3: require("@/assets/coursedetails/st_over.png"),

      icon1: require("@/assets/coursedetails/图层 57.png"),
      icon2: require("@/assets/coursedetails/学科.png"),
      icon3: require("@/assets/coursedetails/学分_线性.png"),
      icon4: require("@/assets/coursedetails/时间.png"),
      icon5: require("@/assets/coursedetails/人.png"),
      icon6: require("@/assets/coursedetails/收藏 拷贝.png"),
      icon12: require("@/assets/coursedetails/leibie.png"),
      icon13: require("@/assets/coursedetails/kcxz.png"),

      icon7: require("@/assets/coursedetails/图层 61.png"),
      icon77: require("@/assets/coursedetails/new/图层 61.png"),
      icon8: require("@/assets/coursedetails/图层 62.png"),
      icon88: require("@/assets/coursedetails/new/图层 62.png"),
      icon9: require("@/assets/coursedetails/课程管理 (1).png"),
      icon99: require("@/assets/coursedetails/new/课程管理.png"),
      icon10: require("@/assets/coursedetails/实验 烧杯 2.png"),
      icon1010: require("@/assets/coursedetails/new/实验 烧杯 2.png"),
      icon11: require("@/assets/coursedetails/视频.png"),
      icon1111: require("@/assets/coursedetails/new/视频.png"),
      collecticon1: require("@/assets/newchange/collect_11.png"),
      collecticon2: require("@/assets/newchange/collect_22.png"),
      testimage: require("@/assets/test.png"),
      testimageicon: require("@/assets/矩形图标.png"),

      image01: require("@/assets/defaultimages/1首页--1实验用房使用排行_23.jpg"),
      image02: require("@/assets/defaultimages/1首页--1实验用房使用排行_25.jpg"),
      image03: require("@/assets/defaultimages/1首页--1实验用房使用排行_36.jpg"),
      image04: require("@/assets/defaultimages/1首页--1实验用房使用排行_60.jpg"),
      image05: require("@/assets/defaultimages/1首页--1实验用房使用排行_57.jpg"),
      image06: require("@/assets/defaultimages/1首页--1实验用房使用排行_41.jpg"),
      image07: require("@/assets/defaultimages/1首页--1实验用房使用排行_44.jpg"),
      image08: require("@/assets/defaultimages/1首页--1实验用房使用排行_03.jpg"),
      itemobj: "",
      resCourseList: [],
      dialogTitle: '教师认证',
      tipsText: '需要完成教师身份认证，且学校设置了选课权限的教师，才能为学校选课哦！选课权限请联系学校平台管理员开通。',
      ruleForm: {
        school_name: '',
        user_name: '',
        staff_number: '',
        pass: '',
      },
      rules: {
        school_name: [
          { required: true, message: '请输入学校名称', trigger: 'blur' }
        ],
        user_name: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        staff_number: [
          { required: true, message: '请输入教工号', trigger: 'blur' }
        ],
        pass: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { required: true, trigger: "blur", validator: validatePass },
        ]
      }
    };
  },
  computed: {},
  filters: {
    dataformat(value) {
      if (!value) return "";
      return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  watch: {
    resCourseList: {
      handler(newVal) {
        let resDis = document.querySelector('.left_cont')
        if (newVal.length > 0) {
          resDis.style['width'] = 'calc(100% - 390px)'
          let dis = document.querySelector('.right_cont')
          if (dis) {
            dis.style['width'] = '390px'
          }
        } else {
          if (resDis) {
            resDis.style['width'] = '100%'
          }
        }
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    let course_sn = decodeURIComponent(this.$route.query.course_sn);
    this.itemobj = course_sn;
    this.getcourseinfo({
      course_sn: course_sn,
    });
    this.initRecCourseList(course_sn)
  },
  methods: {
    initRecCourseList(data) {
      this.resCourseList = []
      getRecCourseList({ course_sn: data, page: 1, per_page: 1000 }).then(res => {
        if (res.code === 0) {
          res.data.data.map(item => {
            if (item.name.length > 14) {
              item.flag = true
            }
          })
          this.resCourseList = res.data.data
        }
      })
    },

    to_detail(item) {
      // debugger
      if (item.is_join == 1) {
        this.$router.push({
          path:
            "/home/wisdompracticeteaching/courselearning?course_sn=" +
            item.course_sn,
        });
      } else {
        this.$router.push({
          path:
            "/home/wisdompracticeteaching/coursedetails?course_sn=" +
            item.course_sn,
        });

        this.reload()
      }
    },
    randomimage() {
      let items = [
        this.image01,
        this.image02,
        this.image03,
        this.image04,
        this.image05,
        this.image06,
        this.image07,
        this.image08,
      ];
      let item = items[Math.floor(Math.random() * items.length)];
      return item;
    },
    collectbt() {
      if (this.token) {

        this.setuserfavorite({
          course_sn: this.itemobj,
          type: 1,
        });
      } else {
        this.$confirm("请先登录!", "登录提示", {
          confirmButtonText: "去登录",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push({
              path: "/login",
            });
          })
          .catch(() => { });
      }
    },
    setuserfavorite(params) {
      setuserfavorite(params)
        .then((res) => {
          console.log(res, "res");
          if (res.code == 0) {
            this.getcourseinfo({
              course_sn: this.itemobj,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    btclick(name, component) {
      this.activebt = name;
      this.componentId = component;
      this.$nextTick(() => {
        this.$refs.mycomponent.initdata(this.courseinfo);
      });
    },
    tolearning() {
      if (this.token) {
        // if (this.courseinfo.is_join == 0) {
        //   this.btloading = true;
        //   this.joincourse({
        //     course_sn: this.itemobj,
        //   });
        // } else if (this.courseinfo.is_join == 1) {
        //   // this.tolearnstate = 0;
        //   this.$router.push({
        //     path: "/home/wisdompracticeteaching/courselearning",
        //     query: {
        //       course_sn: this.courseinfo.course_sn,
        //     },
        //   });
        // }
        let params = {
          course_sn: this.itemobj
        }
        joincourse(params)
          .then((response) => {
            // this.btloading = false;
            if (response.code == 0) {
              this.$message({
                message: '恭喜您，加入课程成功！！！',
                type: 'success'
              });
              this.$router.push({
                path: "/home/wisdompracticeteaching/courselearning",
                query: {
                  course_sn: this.courseinfo.course_sn,
                },
              });
              // this.tolearnstate = 1;
            }
          })
          .catch((error) => {
            console.log(error);
          });

      } else {
        this.$confirm("请先登录!", "登录提示", {
          confirmButtonText: "去登录",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push({
              path: "/login",
            });
          })
          .catch(() => { });
      }

    },
    // joincourse(params) {
    //   joincourse(params)
    //     .then((response) => {
    //       this.btloading = false;
    //       if (response.code == 0) {
    //         // this.tolearnstate = 1;
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    getcourseinfo(params) {
      getcourseinfo(params)
        .then((response) => {
          console.log(response, "response");
          if (response.code == 0) {
            if (response.data.course_status == 0) {
              this.statusImg = this.statusImg1;
            } else if (response.data.course_status == 1) {
              this.statusImg = this.statusImg2;
            } else {
              this.statusImg = this.statusImg3;
            }
            this.courseinfo = response.data;
            // if()

            this.$refs.mycomponent.initdata(this.courseinfo);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toSelection() {
      let flag = true
      if (!flag) {
        // 未认证通过
        this.$refs.customdialog.dialogopenbt()
        return false
      }

      // 认证通过
      let strTip = ''
      let buttonText = ''
      let condition = false
      let str = this.courseinfo.name
      if (condition) { // 开通选课权限
        strTip = `当前课程为：${str}，确定要选课程吗？`
      } else { // 未开通选课权限
        strTip = '您的账号未开通选课权限！请先联系学校学校平台管理员开通选课权限后，才能为学校选课哦！'
        buttonText = '确定'
      }

      this.$confirm(strTip, '学校选课', {
        confirmButtonText: buttonText,
        cancelButtonText: '取消',
        cancelButtonClass: 'cancelExperimental',
        center: true
      }).then(() => { }).catch(err => { });
    },

    dialogCancel(formName) {
      this.$refs[formName].resetFields();
      this.$refs.customdialog.dialogclosebt()
    },
    dialogSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          valid(this.ruleForm).then(res => {
            if (res.code == 0) {
              this.$message.success('认证成功!')
            }
            this.$refs.customdialog.dialogclosebt()
          }).catch(err => { })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
};
</script>
<style scoped lang="scss">
.main-contain_con {
  .contain_up {
    background: url(~@/assets/newchange/bg_4444.jpg) no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 298px;

    .title_con {
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 40px;

      .title_text_border {
        position: relative;
        min-width: 400px;
        height: 160px;
        border-top: 1px solid #ffffff;
        border-bottom: 1px solid #ffffff;

        .left_circle {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 16px;
          height: 16px;
          border: 1px solid #ffffff;
          border-radius: 50%;
          transform: translate(-100%, -50%);
        }

        .right_circle {
          position: absolute;
          bottom: 0px;
          right: 0px;
          width: 16px;
          height: 16px;
          border: 1px solid #ffffff;
          border-radius: 50%;
          transform: translate(100%, 50%);
        }

        .btn_group {
          width: 230px;
          position: absolute;
          bottom: 0px;
          left: 50%;
          transform: translate(-50%, 50%);
          display: flex;
          justify-content: space-between;
          align-items: center;

          ::v-deep.el-button+.el-button {
            margin: 0px;
          }

          .bt_plain {
            border-color: #0773FC;
            color: #0773FC;
          }

          .bt {
            background: #3d84ff;
            border-color: #3d84ff;
            color: #ffffff;
            font-size: 14px;
            font-weight: 400;
          }
        }


        .title_text {
          margin-top: 20px;
          text-align: center;
          font-size: 30px;
          font-weight: bold;
          color: #ffffff;
        }

        .time_text {
          margin-top: 20px;
          text-align: center;
          font-size: 16px;
          font-weight: bold;
          color: #ffffff;
        }
      }
    }
  }

  .contain_down {
    width: 1200px;
    margin: 0px auto;
    display: flex;
    justify-content: center;


    .left_cont {
      width: calc(100% - 390px);
    }

    .right_cont {
      width: 390px;
      padding-left: 150px;
      box-sizing: border-box;

      .title {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #333333;
        padding: 20px 0px 10px;
      }

      .rec_course {
        width: 100%;
        height: 1055px;
        overflow-y: scroll;


        .course_block {
          padding-top: 20px;
          cursor: pointer;

          .course_block_img {
            width: 100%;
            height: 146px;
            border-radius: 5px;
            overflow: hidden;

            ::v-deep .el-image {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .course_block_text {
            width: calc(100% - 20px);
            text-align: center;
            padding: 10px 10px 0px;
            font-size: 14px;
            line-height: 18px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #666666;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .course_block:first-child {
          padding-top: 10px;
        }

        .course_block:last-child {
          padding-bottom: 20px;
        }
      }

      ::v-deep .rec_course::-webkit-scrollbar {
        /*滚动条的宽度*/
        width: 0px;
      }
    }

    .title_con {
      margin-top: 25px;
      border-radius: 4px;

      .title {
        height: 278px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .title-left {
          height: 100%;
          width: 42%;
          position: relative;

          .elimage {
            width: 100%;
            height: 100%;
            border-radius: 5px;
          }

          .statusimg {
            width: 80px;
            height: 80px;
            position: absolute;
            left: -2px;
            top: -2px;
          }
        }

        .title-right {
          width: 58%;
          height: 100%;
          margin-left: 30px;
          display: flex;
          flex-wrap: wrap;
          align-content: space-between;

          .intro-title {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;

            .item {
              width: 50%;
              margin-top: 16px;
              // margin-right: 35px;
              display: flex;
              justify-content: flex-start;
              align-items: center;

              .elimage {
                width: 23px;
                // height: 23px;
              }

              .item-name {
                // margin-left: 10px;
                // margin-right: 10px;
                font-size: 14px;
                font-weight: 500;
                color: #999999;
                white-space: nowrap;
              }

              .item-text {
                font-size: 14px;
                font-weight: 500;
                // color: #333333;
                white-space: nowrap;
              }
            }

            .item:nth-child(1),
            .item:nth-child(2) {
              margin-top: 0px;
            }
          }

          .title-footer {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .item {
              // margin-left: 30px;
              // min-width: 70px;

              .item-bottom {
                // margin-left: 40px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .num {
                  font-size: 30px;
                  font-weight: 500;
                  color: #3d84ff;
                }

                .num-title {
                  font-size: 14px;
                  font-weight: 400;
                  color: #666666;
                }
              }
            }
          }

          .title-right-head {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .right-bt {
              display: flex;
              justify-content: center;
              align-items: center;
              user-select: none;

              .bt2 {
                display: flex;
                justify-content: center;
                align-items: center;

                cursor: pointer;

                .elimage {
                  margin-right: 4px;
                  width: 21px;
                  height: 21px;
                }

                .text {
                  font-size: 16px;
                  font-weight: 500;
                  color: #3d84ff;
                }
              }
            }
          }
        }
      }
    }

    .bottom {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      .bottom_con {
        width: 1200px;
        min-height: 400px;

        .tabbt-contain {
          border-bottom: 1px solid #cccccc;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .bt {
            // margin-right: 90px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 6px;
            border-bottom: 2px solid transparent;
            user-select: none;
            cursor: pointer;

            .bt-icon {
              margin-right: 6px;
            }

            .bt-name {
              font-size: 20px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #222222;
            }

            .bt-name-active {
              color: #3d84ff;
            }
          }

          .btactive {
            border-bottom: 2px solid #3d84ff;
          }
        }
      }
    }
  }

  .dialogbody {
    padding: 20px 70px 40px;

    .tips {
      text-align: center;
      margin-bottom: 30px;
      color: #0773FC;
      line-height: 22px;
    }
  }

  // 弹窗底部样式
  .dialogfooter {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #e7e7e7;

    .score-bt {
      margin-right: 24px;

      .cancelbt {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #0773FC;
        width: 92px;
        height: 36px;
        line-height: 36px;
        padding: 0px;
        background: #ffffff;
        border: 1px solid #0773FC;
        border-radius: 4px;

        &:active {
          background: #0773FC;
        }
      }

      .submitbt {
        // margin-right: 80px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        width: 92px;
        height: 36px;
        line-height: 36px;
        padding: 0px;
        text-align: center;
        background: #0773FC;
        border: 1px solid #0773FC;
        border-radius: 4px;

        &:active {
          background: #0773FC;
        }
      }
    }
  }
}
</style>
