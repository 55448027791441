<template>
  <!-- 教学团队 -->
  <div>
    <el-table
      ref="singleTable"
      :data="tableData"
      highlight-current-row
      style="width: 100%"
    >
      <el-table-column property="name" label="姓名"> </el-table-column>
      <el-table-column property="job_name" label="职称"> </el-table-column>
      <el-table-column property="college_name" label="所属院系">
        <template slot-scope="scope">
          <span>{{ scope.row.college_name }}</span>
          <!-- <template v-if="scope.row.is_leading === 1">
            <el-tag size="medium">主讲老师</el-tag>
          </template> -->
        </template>
      </el-table-column>
      <el-table-column property="is_leading" label="">
        <template slot-scope="scope">
          <template v-if="scope.row.is_leading === 1">
            <el-tag size="medium">主讲老师</el-tag>
          </template>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { getcourseteacherlist } from "@/api/courseteacher";
export default {
  name: "Courseintroduction",
  components: {},
  data() {
    return {
      tableData: [
        // {
        //   name: "王老师",
        //   job_name: "职称",
        //   college_name: "学院名称",
        //   is_leading:'是否主讲人：0：否，1：是'
        // },
      ],
    };
  },
  methods: {
    initdata(obj) {
      if (obj) {
        this.getteacherlist({ course_sn: obj.course_sn });
      }
    },

    getteacherlist(params) {
      getcourseteacherlist(params)
        .then((response) => {
          this.tableData = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style lang="scss" scoped></style>
