<template>
  <!-- 课程介绍 -->
  <div>
    <div class="item">
      <div class="item-head">
        <!-- <div class="dot-icon"></div> -->
        <div class="dot-title">课程介绍</div>
      </div>
      <div class="item-text">
        {{ brief }}
      </div>
    </div>
    <div class="item">
      <div class="item-head">
        <!-- <div class="dot-icon"></div> -->
        <div class="dot-title">课程目标</div>
      </div>
      <div class="item-text">
        {{ target }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Courseintroduction",
  components: {},
  data() {
    return {
      brief: "",
      target: "",
    };
  },
  methods: {
    initdata(obj) {
      this.brief = obj.brief;
      this.target = obj.target;
    },
  },
};
</script>
<style lang="scss" scoped>
.item {
  margin-top: 30px;
  margin-left: 20px;

  .item-head {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .dot-icon {
      width: 4px;
      height: 4px;
      background: #666666;
      border-radius: 50%;
    }

    .dot-title {
      font-size: 24px;
      font-weight: 500;
      color: #000000;
    }
  }

  .item-text {
    text-indent: 2em;
    line-height: 25px;
    margin-top: 15px;
    font-size: 15px;
    font-weight: 400;
    color: #666666;
  }
}
</style>
